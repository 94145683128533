body {
    margin: auto;
    overflow-x: hidden;
}

* {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 0px;
} ::-webkit-scrollbar {
    display: none;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
}
